<template>
  <div>
    <optionscard :deliverParentNum="4"></optionscard>
    <div class="article-top">
      <breadcrumb></breadcrumb>
      <div class="width1200"></div>
      <div class="middleTop">
        <!-- 文章块（左边） -->
        <div class="titleButtonDiv">
          <!-- 文章标题 -->
          <div class="article-title">{{ articleList.title }}</div>
          <!-- 右边的预约按钮 -->
          <div class="appointment1" @click="appointment1()">
            <i class="iconfont icon-yuyue" id="yuyueanniu"></i>预约
          </div>
        </div>
        <div class="article-data">
          <!-- 场馆体验 -->
          <div class="articleTitle">场馆体验</div>
          <!-- 时间 -->
          <div class="articleTime">{{ articleList.issue_time }}</div>
        </div>
      </div>
      <!-- 文章内容 -->
      <div class="article">
        <div class="article-left">
          <!-- 活动时间 -->
          <div class="article-information">
            活动时间: {{ articleList.start_time }} - {{ articleList.end_time }}
          </div>
          <!-- 时长 -->
          <div class="article-information">
            时长: {{ articleList.duration }}分钟
          </div>
          <!-- 场次 -->
          <div class="article-information">
            场次:
            <div class="article-information1">{{ articleList.changci }}</div>
          </div>
          <!-- 主要内容 -->
          <div class="article-information">主要内容：</div>
          <div class="articleContent" v-html="articleList.content"></div>
        </div>
        <!-- 热门活动（右边） -->
        <div class="hot-knowledge-div">
          <div class="hotKnowledgeDivTop">
            <!-- 热门活动字前面的小蓝块 -->
            <div class="bluebulk"></div>
            <div class="hot-knowledge">热门活动</div>
          </div>
          <!-- 热门活动推荐的文章x4 -->
          <div
            class="hot-article-div4"
            v-for="item in hotActivityList"
            :key="item.id"
            @click="clickHotActivity(item.id)"
          >
            <!-- 热门活动 文章标题 -->
            <div class="hot-article-title">{{ item.title }}</div>
            <!-- 热门活动 文章活动时间 -->
            <div class="hot-article-body">
              活动时间: {{ item.start_time }} - {{ item.end_time }}
            </div>
            <!-- 热门活动 文章时长 -->
            <div class="hot-article-data">时长: {{ item.duration }}分钟</div>
            <!-- 热门活动 文章场次 -->
            <div class="hot-article-changci">
              场次:
              <div class="hot-article-changci1">{{ item.changci }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 文章板块下面的 “我要预约” 板块 -->
    <div class="blackbottom" id="appointment1" ref="appoitButton">
      <div class="blackbottom1200">
        <div class="appointment">
          <!-- “我要预约”和小蓝块 -->
          <div class="bluebulk1"></div>
          <div class="appointmentWord">我要预约</div>
        </div>
        <div class="appointment-div">
          <!-- 左边部分 .native 表示对一个组件绑定系统原生事件
                      .prevent 表示提交以后不刷新页面-->
          <el-form
            :model="appointmentForm"
            :rules="appointmentFormRules"
            ref="appointmentForm"
            @submit.native.prevent
          >
            <!-- 姓名input -->
            <el-form-item prop="contacts">
              <el-input
                class="elinput"
                placeholder="姓名"
                v-model="appointmentForm.contacts"
                clearable
              >
                <i slot="prefix" id="nameIcon" class="iconfont icon-ziyuan"></i>
              </el-input>
            </el-form-item>
            <!-- 电话input -->
            <el-form-item prop="mobile">
              <el-input
                class="elinput"
                placeholder="电话"
                v-model="appointmentForm.mobile"
                clearable
              >
                <i
                  slot="prefix"
                  id="phoneIcon"
                  class="iconfont icon-dianhua"
                ></i>
              </el-input>
            </el-form-item>
            <!-- 预约时间 -->
            <el-form-item prop="book_time">
              <el-select
                v-model="appointmentForm.book_time"
                placeholder="预约日期"
                class="elinput"
                clearable
              >
                <el-option
                  v-for="item in DateArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 预约时间段 -->
            <el-form-item prop="changci">
              <el-select
                v-model="appointmentForm.changci"
                placeholder="预约时间段"
                class="elinput"
                clearable
              >
                <el-option
                  v-for="item in TimeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 提交申请按钮 -->
            <div
              class="apply-div"
              @click="submitApplications('appointmentForm')"
            >
              提交申请
            </div>
          </el-form>
          <!-- 右边 “预约须知” -->
          <div class="appointment-right">
            <!-- 预约须知标题 -->
            <div class="noticeWord">预约须知：</div>
            <!-- 预约须知内容 -->
            <div class="noticeContent">
              {{ appointmentList.info }}
            </div>
            <!-- 查看更多预约须知 -->
            <div class="checkMoreButton" @click="getMore()">查看更多></div>
            <!-- 咨询电话 -->
            <div class="noticePhone">咨询热线：+86-754-88888888</div>
            <!-- 位置 -->
            <div class="noticeLocation">地址：广东省汕头市地震局</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看更多预约须知对话框 -->
    <el-dialog title="预约须知" :visible.sync="checkDialogVisible" width="50%">
      <div class="dialogDiv">
        <p v-html="moreForm"></p>
      </div>
    </el-dialog>
    <!-- 提示预约成功 -->
    <el-button :plain="true" @click="open2" class="hintTrue"></el-button>
    <!-- 提示预约失败 -->
    <el-button :plain="true" @click="open3" class="hintFalse"></el-button>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
// import { Event } from './event.js'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      // 存放文章数据
      articleList: [],
      // 存放热门活动数据
      hotActivityList: [],
      // 存放预约须知内容
      appointmentList: [],
      // 存放预约时间数据
      DateArr: [],
      // 存放预约场次数据
      TimeArr: [],
      contacts: '',
      mobile: '',
      book_time: '',
      changci: '',
      // 存放活动详情文章参数
      activityParameter: {
        cate_id: null,
        paginate: 6,
        page: 1
      },
      // 表单数据
      appointmentForm: {
        active_id: '',
        contacts: '',
        mobile: '',
        book_time: '',
        changci: ''
      },
      // 校验提交的信息
      // required:必填  blur:失去焦点
      appointmentFormRules: {
        contacts: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        book_time: [
          { required: true, message: '请选择预约时间', trigger: 'blur' }
        ],
        changci: [
          { required: true, message: '请选择预约时间段', trigger: 'blur' }
        ]
      },
      // 存放预约须知全部数据
      moreForm: {},
      // 控制查看租户对话框的显示与隐藏
      checkDialogVisible: false
    }
  },
  created () {
    const id = this.$route.query.id
    this.getHotActivity(id)
    this.getArticle(id)
  },
  computed: {},
  methods: {
    // 提交按钮
    submitApplications (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.appointmentForm.active_id = this.$route.query.id
          console.log('this.appointmentForm', this.appointmentForm)
          await this.$api.venueactivity.pushForm(this.appointmentForm)
          this.open2()
          this.deleteAppointment()
        }
      })
    },
    // 获取文章数据
    async getArticle (id) {
      const { res } = await this.$api.venueactivity.getActivityArticle({ id })
      console.log('this.articleList', res)

      // 去除时间后面的时分秒
      if (res.issue_time.length > 10) {
        res.issue_time = res.issue_time.slice(0, 10)
      }
      if (res.end_time.length > 10) {
        res.end_time = res.end_time.slice(0, 10)
      }
      if (res.start_time.length > 10) {
        res.start_time = res.start_time.slice(0, 10)
      }
      this.articleList = res
      // 获取预约须知内容
      this.appointmentList = res
      console.log('this.appointmentList', this.appointmentList)
      // 处理预约时间
      const Alldate = this.getAllDate(
        this.articleList.start_time.slice(0, 10),
        this.articleList.end_time.slice(0, 10)
      )
      console.log('Alldate', Alldate)
      for (const item of Alldate) {
        // console.log('item', item)
        this.DateArr.push({
          value: item,
          label: item
        })
      }
      console.log('5', this.DateArr)
      // 处理预约场次（时间段）
      console.log('6', this.articleList.changci.split(','))
      const Alltime = this.articleList.changci.split(',')
      console.log('Alltime', Alltime)
      for (const item of Alltime) {
        this.TimeArr.push({
          value: item,
          label: item
        })
      }
      const k = this.$route.query.k
      this.appointment2(k)
    },
    // 获取热门活动数据
    async getHotActivity (id) {
      const { res } = await this.$api.venueactivity.getHotActivityArticle()
      console.log('res1', res)
      res.forEach((i, index, array) => {
        // 如果url传的id和热门数据里其中一篇文章的数据一样 就把热门数据id一样的文章删掉
        if (i.id === Number(id)) {
          res.splice(index, 1)
        }
      })
      for (const i of res) {
        if (i.start_time.length > 10) {
          i.start_time = i.start_time.slice(0, 10)
        }
        if (i.end_time.length > 10) {
          i.end_time = i.end_time.slice(0, 10)
        }
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
      }
      this.hotActivityList = res
    },
    // 点击热门活动跳转
    async clickHotActivity (id) {
      this.$router.push({ path: 'cghdsecond', query: { id } })
    },

    // 预约按钮(滚动到预约单那里)
    appointment1 () {
      document.querySelector('#appointment1').scrollIntoView(true)
    },
    // 父页面的预约按钮
    appointment2 (k) {
      console.log('k', k)
      const that = this
      that.$nextTick(function () {
        if (k === '' || k === undefined) {
          console.log(1111111)
        } else {
          that.$nextTick(function () {
            window.scrollTo(0, document.documentElement.scrollHeight)
          })
        }
      })
    },
    // 中国标准时间format yyyy-mm-dd
    format (time) {
      let ymd = ''
      const mouth =
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : '0' + (time.getMonth() + 1)
      const day = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()
      ymd += time.getFullYear() + '-' // 获取年份。
      ymd += mouth + '-' // 获取月份。
      ymd += day // 获取日。
      return ymd // 返回日期。
    },
    // 把开始时间和结束时间之间的时间计算出来
    getAllDate (start, end) {
      const dateArr = []
      const startArr = start.split('-')
      const endArr = end.split('-')
      const db = new Date()
      db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2])
      const de = new Date()
      de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2])
      const unixDb = db.getTime()
      const unixDe = de.getTime()
      let stamp
      const oneDay = 24 * 60 * 60 * 1000
      for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(this.format(new Date(parseInt(stamp))))
        stamp = stamp + oneDay
      }
      // console.log('dateArr', dateArr)
      return dateArr
    },
    // 预约须知查看更多对话框
    getMore () {
      this.moreForm = this.appointmentList.info
      this.checkDialogVisible = true
    },
    // 提示预约成功
    open2 () {
      this.$message({
        message: '预约成功！',
        type: 'success'
      })
    },
    // 提示预约失败
    open3 () {
      this.$message({
        message: '预约失败！请填写缺少的信息！',
        type: 'warning'
      })
    },
    // 清空预约单
    deleteAppointment () {
      this.appointmentForm.active_id = ''
      this.appointmentForm.contacts = ''
      this.appointmentForm.mobile = ''
      this.appointmentForm.book_time = ''
      this.appointmentForm.changci = ''
    }
  },

  watch: {
    '$route.query.id' () {
      // 监听路由变化  // 组件第一次进入不会执行
      console.log('id 变化了', this.$route.query.id)
      this.getArticle(this.$route.query.id)
      this.getHotActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
}

.middleTop {
  border-bottom: 1px solid #eaeaea;
}

.titleButtonDiv {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

.article-top {
  margin-bottom: 10px;
}

.article-title {
  width: 1200px;
  margin: 0 auto;
  font-size: 28px;
  color: #333333;
  margin-top: 40px;
  display: inline-block;
}

.appointment1 {
  background-color: #2e88e4;
  width: 105px;
  height: 34px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  display: inline-block;
  margin-top: 40px;
  font-size: 16px;
}

#yuyueanniu {
  line-height: 34px;
  margin-right: 5px;
}

.article-data {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  font-size: 14px;
  color: #999999;
  margin-top: 26px;
  margin-bottom: 38px;
}

.articleTitle {
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-right: 15px;
  background-color: #f7f7f7;
}

.articleTime {
  padding: 0 10px;
  line-height: 25px;
  text-align: center;
  height: 25px;
  background-color: #f7f7f7;
}

.article-information {
  margin-bottom: 10px;
  color: #606060;
}

.article-information1 {
  background-color: #f7f7f7;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  text-align: center;
  padding: 0 10px;
}

.articleContent {
  text-indent: 25px;
}

.article {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.article-left {
  width: 795px;
  float: left;
  font-size: 15px;
}

.hot-knowledge-div {
  width: 315px;
  float: right;
  margin-top: 20px;
  border: solid 1px #eaeaea;
}

.hotKnowledgeDivTop {
  height: 66px;
  display: flex;
  margin-left: 20px;
  line-height: 66px;
}

.article-picture {
  width: 580px;
  height: 362px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.bluebulk {
  width: 4px;
  height: 24px;
  background-color: #2e88e4;
  display: inline-block;
  margin-top: 20px;
}

.hot-knowledge {
  font-size: 24px;
  color: #333333;
  margin-left: 10px;
}

.hot-article-div4 {
  margin: 0 18px;
  padding-top: 40px;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  margin-bottom: 25px;
}

.hot-article-div4:hover .hot-article-title {
  color: #2e88e4;
  cursor: pointer;
}

.hot-article-title {
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-article-body {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999999;
}

.hot-article-data {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999999;
}

.hot-article-changci {
  font-size: 14px;
  color: #999999;
}

.hot-article-changci1 {
  background-color: #f7f7f7;
  display: inline-block;
  padding: 0 10px;
  text-align: center;
}

.blackbottom {
  width: 100%;
  background-color: #394052;
  margin-top: 80px;
  float: right;
}

.blackbottom1200 {
  width: 1200px;
  margin: 0 auto;
}

.bluebulk1 {
  width: 4px;
  height: 24px;
  background-color: #2e88e4;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 15px;
}

.appointmentWord {
  font-size: 20px;
  margin-top: -2px;
  color: #ffffff;
}

.appointment-div {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.appointment-right {
  width: 348px;
  margin-right: 130px;
}

.noticeWord {
  font-size: 16px;
  color: #d7d7d7;
  margin-top: 40px;
}

.noticeContent {
  width: 348px;
  margin-top: 20px;
  font-size: 14px;
  color: #d7d7d7;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.checkMoreButton {
  color: #2e88e4;
  cursor: pointer;
  font-size: 14px;
}

.noticePhone {
  margin-top: 80px;
  color: #d7d7d7;
  font-size: 16px;
}

.noticeLocation {
  color: #d7d7d7;
  font-size: 16px;
}

.appointment {
  display: flex;
  width: 1200px;
  font-size: 20px;
  color: #ffffff;
  border-bottom: 1px solid #5d6470;
  margin: 0 auto;
  padding-top: 35px;
}

#nameIcon,
#phoneIcon {
  margin-left: 5px;
}

::v-deep .blackbottom {
  .elinput {
    margin-top: 20px;
    margin-left: 40px;
    width: auto;
    input {
      height: 50px;
      background-color: #394052;
      width: 2.322rem;
      border-radius: 0;
      border: solid 1px #5d6470;
      color: #95959d;
    }
  }
  .date-picker {
    margin-top: 50px;
    margin-left: 40px;
    input {
      height: 50px;
      background-color: #394052;
      width: 446px;
      border-radius: 0;
      border: solid 1px #5d6470;
    }
  }
  .time-select {
    margin-top: 50px;
    margin-left: 40px;
    input {
      height: 50px;
      background-color: #394052;
      width: 446px;
      border-radius: 0;
      border: solid 1px #5d6470;
    }
  }
}

.iconfont {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 48px;
}

.appointment-div {
  /deep/input::-webkit-input-placeholder {
    font-size: 0.083rem;
    color: #95959d;
  }
  /deep/ input::-moz-input-placeholder {
    font-size: 0.083rem;
    color: #95959d;
  }
  /deep/input::-ms-input-placeholder {
    font-size: 0.083rem;
    color: #95959d;
  }
}

/deep/.el-dialog__header{
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  color: #333333;
}

.el-select-dropdown__item {
  font-size: 0.072rem;
}

.apply-div {
  cursor: pointer;
  width: 163px;
  height: 52px;
  background-color: #2e88e4;
  line-height: 52px;
  margin-top: 45px;
  margin-bottom: 40px;
  margin-left: 150px;
  text-align: center;
  font-size: 16px;
}

.el-input__inner {
  height: 50px;
  background-color: #394052;
  width: 446px;
  border-radius: 0;
  border: solid 1px #5d6470;
}

/deep/ .el-form-item__error {
  left: 50px;
  top: 110%;
  font-size: 14px;
}

.dialogDiv {
  height: 400px;
  overflow: auto;
  width: 690px;
  overflow: auto;
  font-size: 14px;
}

.hintTrue,
.hintFalse {
  display: none;
}
</style>
